import { useQuery } from '@tanstack/react-query';
import api from 'api';
import { showIntercomWithUser } from 'utils/intercom';

export const useMeQuery = (options = {}) => {
  const query = useQuery({
    queryKey: ['get-me'],
    queryFn: () => api.Users.getMe(),
    enabled: !!localStorage.getItem('REACT_TOKEN_AUTH'),
    ...options,
  });

  if (!!localStorage.getItem('REACT_TOKEN_AUTH')) {
    showIntercomWithUser(query.data);
  }

  return query;
};

export const useUserDetail = (id) => {
  return useQuery({
    queryKey: ['get-user', id],
    queryFn: () => api.Users.getDetail(id),
  });
};

export const useListCoachesQuery = (options) => useQuery(['get-list-coaches'], api.Coaches.list, options);

export const useListSessionTemplatesQuery = (options) =>
  useQuery(['get-list-session-templates'], api.SesstionTemplates.list, options);

export const useListCoachesWithParams = (queryParams) =>
  useQuery({
    queryKey: ['get-list-coaches-params', queryParams],
    queryFn: () => api.Coaches.listWithParams(queryParams),
  });

export const useListCoachesEvergreenWithParams = (id, queryParams, coacheeParams) =>
  useQuery({
    queryKey: ['list-coaches-evergreen', queryParams],
    queryFn: () => api.Guests.listWithParamsEvergreen(id, queryParams, coacheeParams),
  });

export const useListCoachesSelfRegistrationWithParams = (id, queryParams, coacheeParams) =>
  useQuery({
    queryKey: ['list-coaches-self-registration', queryParams],
    queryFn: () => api.Guests.listWithParamsSelfRegistration(id, queryParams, coacheeParams),
  });

export const useListGuestsWithParams = (queryParams) =>
  useQuery({
    queryKey: ['get-list-guests-params', queryParams],
    queryFn: () => api.Guests.listWithParams(queryParams),
  });

export const useListTimeZones = () =>
  useQuery({
    queryKey: ['get-list-time-zones'],
    queryFn: () => api.Guests.listTimeZones(),
  });

export const useListAdminsWithParams = (companyId, queryParams) =>
  useQuery({
    queryKey: ['get-list-admins-params', queryParams],
    queryFn: () => api.Companies.listRHsWithParams(companyId, queryParams),
  });

export const useCoachQuery = (id, options) => useQuery(['get-coach', id], () => api.Coaches.getById(id), options);

export const useThemeQuery = (id, options) => useQuery(['get-theme'], () => api.Theme.getById(id), options);

export const useFormatQuery = (id, options) => useQuery(['get-format'], () => api.Format.get(id), options);

export const useEmailTemplateQuery = (id, options) =>
  useQuery(['get-email-template'], () => api.Emails.get(id), options);

export const useGetSubscriptionQuery = (id, options) =>
  useQuery(['get-subscription'], () => api.Subscriptions.getById(id), options);

export const useGetCoachesProgramQuery = (id, options) =>
  useQuery(['get-programs-coaches'], () => api.Programs.getCoaches(id), options);

export const useGetMySubscriptionQuery = (options) =>
  useQuery(['get-my-subscriptions'], api.Subscriptions.getMe, options);

export const useSessionQuery = (id) => {
  const query = useQuery(['get-session'], () => api.Sessions.get(id));
  return query;
};

export const useListFormatThemeQuery = (id, option) =>
  useQuery(['list-format-themes', id], () => api.Companies.listTheme(id), option);

export const useListScopeQuery = (options) => useQuery(['list-scopes'], api.Scopes.list, options);

export const useListScopeQueryWithParams = (queryParams) =>
  useQuery({
    queryKey: ['list-scopes', queryParams],
    queryFn: () => api.Scopes.listWithParams(queryParams),
  });

export const useListDepartmentsQuery = (companyId) =>
  useQuery({
    queryKey: ['list-departments', companyId],
    queryFn: () => api.Departments.list(companyId),
  });

export const useListDepartmentsQueryWithParams = (queryParams) =>
  useQuery({
    queryKey: ['list-departments', queryParams],
    queryFn: () => api.Departments.listWithParams(queryParams),
  });

export const useListEvergreenDepartmentsQuery = (_Link, options) => {
  const { data } = useQuery(
    ['list-evergreen-departments', _Link],
    () => api.Departments.listDepartmentEvergreen(_Link),
    options
  );
  return data;
};

export const useListTargetTemplateItemQuery = (options) =>
  useQuery(['list-target-template-items'], api.TargetTemplateItems.list, options);

export const useListTargetTemplateItemQueryWithParams = (queryParams) =>
  useQuery({
    queryKey: ['get-list-target-template-items-params', queryParams],
    queryFn: () => api.TargetTemplateItems.listWithParams(queryParams),
  });

export const useListSeniorityLevelsQuery = (companyId) =>
  useQuery({
    queryKey: ['list-seniorityLevels', companyId],
    queryFn: () => api.SeniorityLevels.list(companyId),
  });

export const useListSeniorityLevelsQueryWithParams = (queryParams) =>
  useQuery({
    queryKey: ['list-seniorityLevels-params', queryParams],
    queryFn: () => api.SeniorityLevels.listWithParams(queryParams),
  });

export const useListSeniorityLevelMentorsQuery = () =>
  useQuery({
    queryKey: ['list-seniorityLevels'],
    queryFn: () => api.SeniorityLevels.listSeniorityMentor(),
  });

export const uselistSeniorityEvergreenQuery = (_Link, options) => {
  const { data } = useQuery(
    ['list-evergreen-seniorityLevels', _Link],
    () => api.SeniorityLevels.listSeniorityEvergreen(_Link),
    options
  );
  return data;
};

export const useListProgramsQuery = ({ filterSubscriptionState }) => {
  const { data, isLoading } = useQuery(
    [
      'list-programs',
      ...Object.keys(filterSubscriptionState)
        .sort()
        .map((property) => filterSubscriptionState[property]),
    ],
    () => api.Programs.list(filterSubscriptionState)
  );
  return { data, isLoading };
};

export const useGetCompany = (id, options = {}) =>
  useQuery({
    queryKey: ['get-company', id],
    queryFn: () => api.Coaches.getCoachCompany(id),
    ...options,
  });

export const useGetTaretsTemplateItemsPerCompany = (companyId) =>
  useQuery({
    queryKey: ['get-company-target-template-items', companyId],
    queryFn: () => api.Companies.getTaretsTemplateItems(companyId),
  });

export const useListProgramsQueryWithParams = (queryParams) =>
  useQuery({
    queryKey: ['list-programs', queryParams],
    queryFn: () => api.Programs.list(queryParams),
  });

export const useListProgramsQueryWithParamsCsv = (queryParams) =>
  useQuery({
    queryKey: ['list-programs-csv', queryParams],
    enabled: false, // Prevents the query from executing automatically
    refetchOnWindowFocus: false, // Does not reload data at each window focus
    queryFn: () => api.Programs.listCsv(queryParams),
  });

export const useGetProgramsQuery = (id) => {
  const query = useQuery(['get-programs', id], () => api.Programs.get(id));
  query.data?.sessions.sort((a, b) => a.number - b.number);
  return query;
};

export const useGetProgramDocumentsQuery = (id) =>
  useQuery(['get-program-documents'], () => api.Program_Documents.getByProgramId(id));

export const useGetSessionNotesQuery = (id) =>
  useQuery(['get-session-notes', id], () => api.Session_Notes.getBySessionId(id));

export const useSessionNoteQuery = (id) => {
  return useQuery({
    queryKey: ['get-session-note', id],
    queryFn: async () => {
      if (!id) return;
      return await api.Session_Notes.get(id);
    },
  });
};

export const useListCompaniesQuery = (options) => useQuery(['get-list-companies'], api.Companies.list, options);
export const useListCompaniesWithParams = (queryParams) =>
  useQuery({
    queryKey: ['get-list-companies-params', queryParams],
    queryFn: () => api.Companies.listWithParams(queryParams),
  });

export const useCompanyQuery = (id, options) => useQuery(['get-company'], () => api.Companies.get(id), options);

export const useListFormatsQuery = (options) => useQuery(['get-list-formats'], api.Format.list, options);

export const useListThemesQuery = (options) => useQuery(['get-list-themes'], api.Theme.list, options);

export const useListCompanyRessourcesQuery = (options) =>
  useQuery(['get-list-company-ressources'], api.CompanyRessources.list, options);

export const useListEmailsQuery = (options) => useQuery(['get-list-emails'], api.Emails.list, options);

export const useListEmailsWithParams = (queryParams) =>
  useQuery({
    queryKey: ['get-list-emails', queryParams],
    queryFn: () => api.Emails.listWithParams(queryParams),
  });

export const useListEmailsWithName = (name) => {
  return useQuery({
    enabled: !!name,
    queryKey: ['get-name-email', name],
    queryFn: () => api.Emails.getWithName(name),
  });
};

export const useListEmailsCompanyWithParams = (id, queryParams) =>
  useQuery({
    queryKey: ['get-list-emails', queryParams],
    queryFn: () => api.Companies.listEmailTemplatesWithParams(id, queryParams),
  });

export const useSessionActionQuery = (id) => useQuery(['get-session-action', id], () => api.Actions.listBySession(id));

export const useVerifyMeQuery = ({ id }) => {
  return useQuery(['verifyAccount'], () => api.Auth.verifyAccount({ id }), { retry: false });
};

export const useDashboardListQuery = (body, options) =>
  useQuery(['get-stats-list', ...Object.values(body)], () => api.Stats.list({ body }), options);

export const useListFormSettings = ({ id, queryParams }) => {
  const data = useQuery(['get-form-settings', id, queryParams?.isMentor], () =>
    api.FormSettings.getFormSettings(id, queryParams)
  );
  return data;
};

export const useListFormSettingsPublic = ({ _Link, queryParams }) => {
  const data = useQuery(['get-form-settings-public', _Link, queryParams?.isMentor], () =>
    api.FormSettings.getFormSettingsPublic(_Link, queryParams)
  );
  return data;
};

export const useListRemindersSettings = () => {
  return useQuery(['get-reminders-settings'], api.Reminders.getRemindersSettings);
};

export const useSelfRegistrationCode = (companyId, enabled = true) => {
  return useQuery({
    enabled,
    queryKey: ['get-self-registration-code', companyId],
    queryFn: () => api.Companies.getSelfRegistrationCode(companyId),
  });
};

export const useCoachAvailabilities = (coachId, onlyFullAvailabilities = false, enabled = true) => {
  return useQuery({
    enabled,
    queryKey: ['get-coach-availabilities', coachId],
    queryFn: () => api.CoachAvailability.get(coachId, onlyFullAvailabilities),
  });
};

export const useActivitiesList = (queryParams) =>
  useQuery({
    queryKey: ['get-activities', queryParams],
    queryFn: () => api.Activities.list(queryParams),
  });

export const useExercisesList = (queryParams, enabled = true) => {
  return useQuery({
    enabled,
    queryKey: ['get-exercises', queryParams],
    queryFn: async () => {
      if (!queryParams || !queryParams.activityId) return;
      return await api.Activities.exercisesList(queryParams);
    },
  });
};
