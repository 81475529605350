import React, { useState, useEffect, useRef } from 'react';
import ViewModal from 'ui/components/shared/ViewModal';
import api from 'api';
import ChooseExpertContent from 'ui/components/ChooseExpertContent/ChooseExpertContent';
import CoachView from 'ui/components/shared/coaches/CoachView';
import { useChooseExpert } from 'hooks/usecase/useChooseExpert';
import Button from 'ui/components/Button/Button';
import { Box, FormControl, FormHelperText, Typography, TextField } from '@mui/material/';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SummarySelfRegistration from 'ui/components/shared/SummarySelfRegistration/SummarySelfRegistration';
import GoogleCalendarButton from 'ui/components/GoogleCalendarButton/GoogleCalendarButton';
import OutlookCalendarButton from 'ui/components/OutlookCalendarButton/OutlookCalendarButton';
import { DateTime } from 'luxon';
import { useListFormSettingsPublic } from 'hooks/queries';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { userModalNextPaginationManagement, userModalPreviousPaginationManagement } from 'utils/pagination';
import styles from './SelfRegistrationModal.styles';

const FRONT_URL = window.location.href.replace(window.location.pathname, '');

const SelfRegistrationModal = ({ company = {}, theme, selfRegistrationLink, open, user, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [coachIdSelected, setCoachIdSelected] = useState(null);
  const [showCoachIdSelected, setShowCoachIdSelected] = useState(null);
  const [availabilitySelected, setAvailabilitySelected] = useState(null);
  const [yourRequest, setYourRequest] = useState('');
  const [isLoadingCreateSelfRegistration, setIsLoadingCreateSelfRegistration] = useState(false);
  const [queryParams, setQueryParams] = useState({ page: 1, size: 20 });
  const [modalCoachActionType, setModalCoachActionType] = useState('');
  const queryClient = useQueryClient();
  const modalRef = useRef();

  const googleDescription = `${t('event_calendar_description')} <a href="${FRONT_URL}">${FRONT_URL.replace(
    `${window.location.protocol}//`,
    ''
  )}</a>`;

  const outlookDescription = `${t('event_calendar_description')} ${FRONT_URL}`;

  const { data: formSettingsList } = useListFormSettingsPublic({
    _Link: company.applicationLink,
    queryParams: { isMentor: false },
  });
  const { totalPages, coaches, isLoadingCoaches, isFetched } = useChooseExpert(
    selfRegistrationLink,
    queryParams,
    'selfRegistration'
  );

  const changeStep = (step) => {
    setCurrentStep(step);
    console.log('modalRef', modalRef);
    modalRef.current.scrollTop = 0;
  };

  const createSelfRegistrationMutation = useMutation(api.SelfRegistration.createSelfRegistration, {
    onSuccess: async () => {
      changeStep(4);
      await queryClient.invalidateQueries(['get-me'], { refetchInactive: true });
      setIsLoadingCreateSelfRegistration(false);
    },
  });

  const { expertLabel } = company;

  const isExpectationsActivated = formSettingsList?.formFields?.find(
    ({ fieldName }) => fieldName === 'expectations'
  )?.isActivated;

  const onCloseModal = () => {
    setQueryParams({ page: 1, size: 20 });
    changeStep(1);
    onClose();
  };

  const createSelfRegistration = () => {
    const payload = { availabilityId: availabilitySelected.id, themeId: theme.id, coachId: coachIdSelected };

    if (isExpectationsActivated) {
      payload.expectations = yourRequest;
    }

    setIsLoadingCreateSelfRegistration(true);
    createSelfRegistrationMutation.mutate(payload);
  };

  useEffect(() => {
    if (currentStep === 2 && coaches && coaches.length && !isLoadingCoaches) {
      if (modalCoachActionType === 'next') {
        setModalCoachIdSelected(coaches?.[0]?.id);
      } else {
        setModalCoachIdSelected(coaches?.[coaches.length - 1]?.id);
      }
    }
  }, [queryParams.page, isLoadingCoaches]);

  const setModalCoachIdSelected = (coachId) => {
    setCoachIdSelected(coachId);
    setShowCoachIdSelected(coachId);
    setAvailabilitySelected(null);
  };

  const footerContent = () => {
    if (currentStep === 1 && coachIdSelected) {
      return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Button
            sx={{ width: { sm: '400px', xs: '95%' } }}
            onClick={() => {
              setShowCoachIdSelected(coachIdSelected);
              changeStep(2);
            }}>
            {t('view_mentor_availability')}
          </Button>
        </Box>
      );
    }

    if (currentStep === 2) {
      return (
        <Box sx={styles.footerContainer}>
          <Button
            sx={{ borderColor: 'primary.border', color: '#495465', fontWeight: 400, ...styles.footerButton }}
            variant="secondary"
            onClick={() => {
              setShowCoachIdSelected(null);
              changeStep(1);
            }}>
            <ArrowBackIcon sx={{ marginRight: '6px' }} />
            {t('view_all_profiles')}
          </Button>

          <Button
            sx={{ ...styles.footerButton, marginTop: { xs: '8px', sm: 0 } }}
            disabled={!availabilitySelected}
            onClick={() => {
              setCoachIdSelected(showCoachIdSelected);
              changeStep(3);
            }}>
            {t('choose_this_mentor')}
          </Button>
        </Box>
      );
    }

    if (currentStep === 3) {
      return (
        <Box sx={styles.footerContainer}>
          <Button
            sx={{ borderColor: 'primary.border', color: '#495465', fontWeight: 400, ...styles.footerButton }}
            variant="secondary"
            onClick={() => {
              setAvailabilitySelected(null);
              changeStep(2);
            }}>
            <ArrowBackIcon sx={{ marginRight: '6px' }} />
            {t('previous_step')}
          </Button>

          <Button
            sx={{ ...styles.footerButton, marginTop: { xs: '8px', sm: 0 } }}
            loading={isLoadingCreateSelfRegistration}
            disabled={(isExpectationsActivated && !yourRequest) || isLoadingCreateSelfRegistration}
            onClick={() => createSelfRegistration()}>
            {t('validate_request')}
          </Button>
        </Box>
      );
    }

    if (currentStep === 4) {
      return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Button
            disabled={!yourRequest}
            onClick={async () => {
              await queryClient.invalidateQueries(['get-me'], { refetchInactive: true });
              navigate('/home');
            }}>
            {t('back_to_home_page')}
          </Button>
        </Box>
      );
    }

    return;
  };

  const content = () => {
    if (currentStep === 1) {
      return (
        <Box>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 500,
            }}>
            {t('self_registration_modal_title', { expertLabel })}
          </Typography>
          <Typography
            sx={{
              paddingTop: '8px',
              fontSize: '16px',
              fontWeight: 300,
            }}>
            {t('self_registration_modal_subtitle', { expertLabel })}
          </Typography>

          <ChooseExpertContent
            company={company}
            totalPages={totalPages}
            coaches={coaches}
            isLoadingCoaches={isLoadingCoaches}
            isFetched={isFetched}
            queryParams={queryParams}
            onlyOneSelectableCoach
            setQueryParams={setQueryParams}
            showFilterButton
            showAvailabilitiesCount
            showSelectButton={false}
            contentCenter
            onShowExpert={(coachId) => {
              setShowCoachIdSelected(coachId);
              setAvailabilitySelected(null);
              changeStep(2);
            }}
            onSelect={(id) => {
              setCoachIdSelected(id);
            }}
          />
        </Box>
      );
    }

    const coach = coaches?.find(({ id }) => showCoachIdSelected === id);

    if (currentStep === 2 && coach) {
      return (
        <CoachView
          userRole={user.role}
          coach={coach}
          company={user.company}
          directionalArrowsActivated
          onlyFullAvailabilities
          availabilitiesDisabled={false}
          redBorderNoAvailabilities
          subtitleAvailabilities={t('select_availability')}
          onClickPreviousCoach={() => {
            const coachIndex = coaches.findIndex((item) => item.id === showCoachIdSelected);
            setModalCoachActionType('previous');

            userModalPreviousPaginationManagement({
              userIndex: coachIndex,
              users: coaches,
              totalPages,
              queryParams,
              setUserIdSelected: setModalCoachIdSelected,
              setQueryParams,
            });
          }}
          onClickNextCoach={() => {
            const coachIndex = coaches.findIndex((item) => item.id === showCoachIdSelected);
            setModalCoachActionType('next');

            userModalNextPaginationManagement({
              userIndex: coachIndex,
              users: coaches,
              totalPages,
              queryParams,
              setUserIdSelected: setModalCoachIdSelected,
              setQueryParams,
            });
          }}
          onSelectAvailability={(availability) => {
            setAvailabilitySelected(availability);
          }}
        />
      );
    }

    if (currentStep === 3) {
      return (
        <Box>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 500,
            }}>
            {t('finalizing_request_title')}
          </Typography>
          <Typography
            sx={{
              paddingTop: '8px',
              fontSize: '16px',
              fontWeight: 300,
            }}>
            {t('finalizing_request_desc')}
          </Typography>

          {isExpectationsActivated && (
            <FormControl sx={{ width: '100%', marginBottom: '8px' }}>
              <TextField
                sx={{ marginTop: '16px', width: '100%' }}
                label={t('finalizing_request_input')}
                value={yourRequest}
                rows={3}
                maxRows={4}
                multiline
                variant="outlined"
                onChange={(event) => {
                  setYourRequest(event.target.value);
                }}
              />
            </FormControl>
          )}

          <Box sx={{ marginTop: '10px' }}>
            <SummarySelfRegistration
              availability={availabilitySelected}
              coach={coaches.find(({ id }) => showCoachIdSelected === id)}
              onModifyMyChoice={() => {
                setAvailabilitySelected(null);
                changeStep(2);
              }}
            />
          </Box>
        </Box>
      );
    }

    if (currentStep === 4) {
      return (
        <Box>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 500,
            }}>
            {t('self_registration_reserved_title', { sessionLabel: company.sessionLabel })}
          </Typography>
          <Typography
            sx={{
              paddingTop: '8px',
              fontSize: '16px',
              fontWeight: 300,
              marginBottom: '16px',
            }}>
            {t('self_registration_reserved_desc', { sessionLabel: company.sessionLabel })}
          </Typography>

          <SummarySelfRegistration
            availability={availabilitySelected}
            coach={coaches.find(({ id }) => showCoachIdSelected === id)}
          />

          <Typography
            sx={{
              paddingTop: '8px',
              fontSize: '16px',
              fontWeight: 300,
              marginTop: '24px',
              marginBottom: '12px',
            }}>
            {t('self_registration_reserved_calendar')}
          </Typography>

          <Box sx={{ marginTop: '24px', gap: '16px', display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <OutlookCalendarButton
              description={outlookDescription}
              subject={t('self_registration_reserved_subject_calendar', { themeName: theme.name })}
              date={DateTime.fromISO(availabilitySelected.date).toUTC().toString()}
              minuteDuration={availabilitySelected.duration}
              email={coaches.find(({ id }) => showCoachIdSelected === id)?.email}
            />

            <GoogleCalendarButton
              description={googleDescription}
              subject={t('self_registration_reserved_subject_calendar', { themeName: theme.name })}
              date={DateTime.fromISO(availabilitySelected.date).toUTC().toString()}
              minuteDuration={availabilitySelected.duration}
              email={coaches.find(({ id }) => showCoachIdSelected === id)?.email}
            />
          </Box>
        </Box>
      );
    }
  };

  const maxWidth = currentStep === 3 || currentStep === 4 ? '800px' : '90%';

  return (
    <ViewModal
      contentRef={modalRef}
      open={open}
      positionClose="sticky"
      onClose={onCloseModal}
      customStyleBox={{
        padding: 0,
        width: '90%',
        maxWidth,
        borderRadius: '16px',
        position: 'relative',
        height: 'none',
        maxHeight: '80%',
      }}>
      <Box sx={{ padding: '24px' }}>{content()}</Box>

      {footerContent() && <SelfRegistrationFooter>{footerContent()}</SelfRegistrationFooter>}
    </ViewModal>
  );
};

const SelfRegistrationFooter = ({ children }) => {
  return (
    <Box
      sx={{
        padding: '16px 24px',
        background: '#FFFFFFE6',
        position: 'sticky',
        bottom: '0',
        margin: '0',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        display: 'flex',
        zIndex: '10000',
      }}>
      {children}
    </Box>
  );
};

export default SelfRegistrationModal;
