import React from 'react';

import styles from './Button.styles';

import { Button as NativButton, CircularProgress } from '@mui/material';

const Button = ({ children, sx, size = 'lg', variant = 'primary', loading = false, ...props }) => {
  const getType = () => {
    switch (variant) {
      case 'primary':
        return styles.primary;
      case 'secondary':
        return styles.secondary;
      case 'tertiary':
        return styles.tertiary;
      case 'delete':
      case 'delete-tertiary':
        return styles.delete;
      default:
        break;
    }
  };

  const getVariant = () => {
    switch (variant) {
      case 'primary':
        return 'contained';
      case 'secondary':
      case 'delete':
        return 'outlined';
      case 'tertiary':
      case 'delete-tertiary':
        return 'text';
      default:
        break;
    }
  };

  const getSize = () => {
    switch (size) {
      case 'xlg':
        return styles.xlg;
      case 'lg':
        return styles.lg;
      case 'xs':
        return styles.xs;
      default:
        break;
    }
  };

  return (
    <NativButton variant={getVariant()} sx={[styles.button, getType(), getSize(), sx]} {...props}>
      {loading && <CircularProgress color="inherit" size="22px" sx={{ marginRight: '8px' }} />}
      <span style={styles.content}>{children}</span>
    </NativButton>
  );
};

export default Button;
